<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <h2>New Customer</h2>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label>Customer Name:</label>
                <input [(ngModel)]="newCustomer.customer" class="form-control" [class.is-invalid]="newCustomer.errors.customer" type="text"/>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label>Admin Emails: (separate with ",")</label>
                <input [(ngModel)]="newCustomer.admins" class="form-control" [class.is-invalid]="newCustomer.errors.admins" type="text"/>
            </div>
        </div>

        <div class="col-4">
            <div class="form-group">
                <label>Total Single Student Licenses:</label>
                <input [(ngModel)]="newCustomer.singleCount" type="number" class="form-control" [class.is-invalid]="newCustomer.errors.singleCount" />
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <label>Total Multi Student Licenses:</label>
                <input [(ngModel)]="newCustomer.multiCount" type="number" class="form-control" [class.is-invalid]="newCustomer.errors.multiCount" />
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <label>Expiration:</label>
                <input [(ngModel)]="newCustomer.expiration" class="form-control" [class.is-invalid]="newCustomer.errors.expiration" type="date"/>
            </div>
        </div>
        <div class="col-12">
            <button (click)="create()" class="btn btn-primary">Create</button>
            <button (click)="cancelNew()" class="btn btn-danger">Cancel</button>
        </div>
    </div>
    <div>
        <h2>Impersonate User</h2>
        <label>UserId</label>
        <input [(ngModel)]="impersonateUserId" class="form-control" />
        <button (click)="impersonate()" class="btn btn-primary">Impersonate</button>
    </div>
    <div class="row" >
        <div class="col-12">
            <table class="table" *ngIf="licenses">
                <thead>
                    <th>Customer</th>
                    <th>Admins</th>
                    <th class="number-col">Single Student</th>
                    <th class="number-col">Remaining</th>
                    <th class="number-col">Multi Student</th>
                    <th class="date-col">Expiration</th>
                    <th *ngIf="showConfig">Actions</th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let license of displayLicenses">
                        <tr>
                            <td>
                                <input *ngIf="showConfig" class="form-control" [(ngModel)]="license.customer"/>
                            </td>
                            <td>
                                <input *ngIf="showConfig" class="form-control" [(ngModel)]="license.admins"/>
                            </td>
                            <td>
                                <input *ngIf="showConfig" type="number" class="form-control" [(ngModel)]="license.singleCount"/>
                            </td>
                            <td [class.number-text]="showConfig">
                                {{license.singleRemaining}}
                            </td>
                            <td>
                                <input *ngIf="showConfig" type="number" class="form-control" [(ngModel)]="license.multiCount"/>
                            </td>
                            <td>
                                <input *ngIf="showConfig" class="form-control" [(ngModel)]="license.expiration" type="date" />
                            </td>
                            <td *ngIf="showConfig">
                                <a (click)="save(license)"><i class="fa fa-floppy-o" aria-hidden="true"></i></a> &nbsp;
                                <a (click)="delete(license)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                            </td>
                        </tr>
                        <tr *ngIf="license.features && showConfig">
                            <td colspan="7">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.dashboard"/> Dashboard</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.snapshot"/> Snapshot</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.browserTracking"/> Browser Tracking</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.download"/> Download</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.manage"/> Manage</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.supportChanges"/> Support Changes</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.schedule"/> Schedule</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.devices"/> Devices</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.behaviorTargets"/> Behavior Targets</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.response"/> Response Tracking</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.emailTextNotifications"/> Email/Text/Dashboard Notifications</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.manageStudentTemplates"/> Student Templates</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.manageAppTemplates"/> App Templates</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.manageResponses"/> Manage Responses</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.abc"/> ABC</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.notifications"/> Notifications</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.appGroups"/> App Groups</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.documents"/> Documents</label></div>
                                        <div class="col-4"><label><input type="checkbox" [(ngModel)]="license.features.intervalWBaseline"/> Interval Tracking w/ Baseline</label></div> 
                                        <div class="col-6">
                                            <label>Intensity: &nbsp;</label>
                                            <input type="number" [(ngModel)]="license.features.intensity"/>
                                        </div>
                                        <div class="col-6">
                                            <label>Snapshot Measurements</label>
                                            <div>
                                                <textarea class="form-control" [(ngModel)]="license.snapshotConfigNames" rows="4"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            License: {{license.license}}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="showStats && license.licenseDetails.stats">
                            <td colspan="6">
                                <div>Student Count: {{license.licenseDetails.stats.studentStats.studentCount}}</div>
                                <div>Students With Behaviors: {{license.licenseDetails.stats.studentStats.studentsWithBehaviors}}</div>
                                <div>Students Tracked: {{license.licenseDetails.stats.studentStats.studentsTracked}}</div>
                                <div>Deleted: {{license.licenseDetails.stats.studentStats.deletedCount}}</div>
                            </td>
                        </tr>
                        <tr *ngIf="showStats && license.usage && license.usage.length > 0">
                            <td colspan="7">
                                <div class="container-fluid">
                                    <div class="row">
                                        <ng-container *ngFor="let usage of license.usage">
                                            <div class="col-4">
                                                <label>{{usage.month}}</label>
                                                <div>Events Tracked: {{usage.trackedEvents}}</div>
                                                <div>Students Tracked: {{usage.studentsTracked}}</div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>