<app-loading *ngIf="selected && selected.saving" [absolute]="true" text="Saving"></app-loading>
<app-loading *ngIf="loading" [absolute]="true" text="Loading"></app-loading>
<div *ngIf="!loading">
    <div *ngIf="!selected.token">
        <div class="form-group" *ngIf="!hideName">
            <label>Phone or Tablet Name</label>
            <input id="student-setting-device-app-name" class="form-control" [(ngModel)]="selected.deviceName" [readonly]="!administrator" />
        </div>
        <div class="form-group">
            <label>Student Display Name</label>
            <input id="student-setting-device-app-student-name" class="form-control" [(ngModel)]="selected.studentName" [readonly]="!administrator" />
        </div>
        <div class="form-group" *ngIf="groupsEnabled">
            <label>Groups</label>
            <app-mtt-tags typeText="group" [(tagField)]="selected.groups"></app-mtt-tags>
        </div>
        <div *ngIf="!selected.validated && selected.dsn && !hideName">
            <h4>The QR Code has not been claimed</h4>
        </div>
        <div *ngIf="!selected.dsn">
            <label>QRCode expires after the number of days specified</label>
            <input id="student-setting-device-app-qr-expire" type="number" class="form-control" [(ngModel)]="qrExpires"/>
        </div>
        <div>
            <label><input [(ngModel)]="selected.textAlerts" type="checkbox"> Allow note tagging notifications</label>
        </div>
        <div *ngIf="selected.dsn">
            <a id="student-setting-device-app-gen-qr" class="link-item" (click)="generateQrCode()">Show QRCode</a>
        </div>
        <div class="form-group">
            <label>Trackable Behaviors</label>
            <div *ngIf="invalidAbc" class="warning"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> ABC Tracking has been enabled, but no antecedents or consequences have been defined on the ABC tab. ABC tracking will be not be visible on this device at this time.</div>
            <table class="table">
                <thead>
                    <th>Order</th>
                    <th>Behavior</th>
                    <th>Track</th>
                    <th *ngIf="abcEnabled">ABC</th>
                    <th *ngIf="intensityEnabled">Intensity</th>
                </thead>
                <tbody>
                    <tr *ngFor="let behavior of activeBehaviors; index as i">
                        <td *ngIf="!readonly">
                            <a [id]="'student-setting-device-app-bo-up-' + i" (click)="changeOrder(behavior, -1)">
                                <i class="fa fa-chevron-up"></i>
                            </a>&nbsp;
                            <a [id]="'student-setting-device-app-bo-down-' + i" (click)="changeOrder(behavior, 2)">
                                <i class="fa fa-chevron-down"></i>
                            </a>
                        </td>
                        <td [class.responseItem]="behavior.isResponse">{{behavior.studentBehavior.name}} <span><i *ngIf="behavior.isResponse" class="fa fa-info-circle" title="response"></i></span></td>
                        <td><input [id]="'student-setting-device-app-track-' + i" type="checkbox" [(ngModel)]="behavior.event.track" [disabled]="readonly" /></td>
                        <td *ngIf="abcEnabled"><input [id]="'student-setting-device-app-abc-' + i" type="checkbox" [(ngModel)]="behavior.event.abc" [disabled]="readonly"/></td>
                        <td *ngIf="intensityEnabled && behavior.isIntensity">
                            <input [id]="'student-setting-device-app-intensity-' + i" type="checkbox" [(ngModel)]="behavior.event.intensity" [disabled]="readonly"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="form-group" *ngIf="responses && responses.length > 0">
            <label>Trackable Responses</label>
            <table class="table">
                <thead>
                    <th>Response</th>
                    <th>Track</th>
                </thead>
                <tbody>
                    <tr *ngFor="let response of responses">
                        <td>{{response.name}}</td>
                        <td><input type="checkbox" [checked]="isBehaviorCheckedForApp(response)" (change)="toggleBehaviorForApp(response)"/></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-center" *ngIf="!readonly">
            <button id="student-setting-device-app-save" class="btn btn-primary" (click)="save()">Save</button>
            <button id="student-setting-device-app-cancel" class="btn btn-secondary" (click)="cancel()" *ngIf="!selected.isNew">Cancel</button>
            <button id="student-setting-device-app-remove" class="btn btn-danger" (click)="remove()" *ngIf="!selected.isNew">Remove</button>
        </div>
    </div>
    <div *ngIf="selected.token">
        <div id="qrCodeArea">
            <div id="printTarget">
                <div class="">
                    <h1><img id="setup-logo" height="45" src="/assets/images/logo-dark.gif"/> Setup Instructions</h1>
                    <p>Mobile Device Name: {{selected.deviceName}}</p>

                    <p>
                        The mytaptrack&reg; mobile app is another convenient way to securely track
                        behaviors and durations.
                    </p>
                    <h2>Security</h2>
                    <p>
                        While mytaptrack&reg; continually works to ensure that information is stored and communicated
                        securely, it is your responsibility to ensure that information displayed on the phone or tablet
                        is done in a secure manner.
                    </p>
                    <h2>Setting up the mobile device with the QR Code</h2>
                    <ul>
                        <li>1. Download the mytaptrack&reg; app from the Apple or Android App Store</li>
                        <li>2. Open the mytaptrack&reg; app</li>
                        <li>3. On the navigation bar at the bottom of the screen  click "Settings"</li>
                        <li>4. Click "Scan QR Code"</li>
                        <li>5. Use the app to scan the QR Code provided in this letter/document</li>
                        <li>6. The student to be tracked is now displayed at the bottom of the settings screen</li>
                        <li>7. Click "Home" from the navigation bar at the bottom of the screen</li>
                        <li>8. Select the student to be tracked from the scroll wheel and you are now ready to track for that student. To switch to another student, select from the scroll wheel.</li>
                    </ul>
                </div>
                <div class="text-center">
                    <qrcode [qrdata]="selected.token" [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
                </div>
            </div>
        </div>
        <div>
            <button class="btn btn-primary" (click)="closeQrCode()">Close</button>
            <button class="btn btn-primary" ngxPrint printSectionId="qrCodeArea">Print with Instructions</button>
        </div>
    </div>
</div>