<div class="row">
    <div class="col-12">
        <h2>Devices</h2>
    </div>
</div>
<div class="row">
    <div class="col-4">
        <ul>
            <li *ngFor="let item of devices.items; index as i" [class.selected]="selected && item.dsn == selected.dsn">
                <a [id]="'student-setting-devices-' + i" (click)="setSelected(item)">{{item.deviceName}}</a>
            </li>
            <li *ngIf="!templatesOnly" [class.selected]="selected && !selected.validated && !qrCode && !selected.isApp">
                <a id="student-setting-device-track-2-0" *ngIf="administrator" title="Assign new device" (click)="create()"><i class="fa fa-plus"></i> Register Track 2.0</a>
            </li>
            <li *ngIf="!templatesOnly" [class.selected]="selected && selected.isApp && selected.isNew">
                <a id="student-setting-device-app" *ngIf="administrator" title="Assign new device" (click)="createApp()"><i class="fa fa-plus"></i> Register Mobile App</a>
            </li>
            <li *ngIf="templatesOnly">
                <a id="student-setting-device-app" *ngIf="administrator" title="Assign new device" (click)="createApp()"><i class="fa fa-plus"></i> Create App Template</a>
            </li>
        </ul>
    </div>
    <div class="col-8 details" style="position:relative" *ngIf="selected && selected.isNew && !selected.isApp && !qrCode">
        <div *ngIf="!loading">
            <div *ngIf="!selected.isRegistered">
                <div class="form-group">
                    <label>DSN (Device Serial Number)</label>
                    <input id="student-setting-device-track-2-0-dsn" class="form-control" [(ngModel)]="selected.dsn">
                </div>
                <div class="form-group">
                    <button id="student-setting-device-track-2-0-register" class="btn btn-primary" 
                            [class.btn-primary]="isDsnValid()" 
                            [class.btn-secondary]="!isDsnValid()" 
                            (click)="selected.register()">Register</button>
                </div>
                <div>
                    <h4>Where to find it</h4>
                    <img alt="Where to find the DNS" style="width:100%" src="assets/images/dsn-overview.png" />
                </div>
            </div>
            <div *ngIf="selected.isRegistered && !selected.isVerified">
                <div id="clickTheButton" class="text-center">
                    <div>Click the device to verify possession</div>
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                </div>
                <div id="wifiRegistrationConnectV1" class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <h4>Have you configured your device's Wi-Fi? Here's how</h4>
                        </div>
                        <div class="col-12 pannel-header">
                            <a class="btn btn-primary" style="float:right" href="assets/pdf/mytaptrack-wifi-setup.pdf" download="mytaptrack-wifi-setup"><i class="fa fa-download" aria-hidden="true"></i> Download PDF</a>
                            <h5>Step-by-Step Instructions</h5>
                        </div>
                        <div class="col-12 pannel-body" [class.minimize]="!stepsExpanded">
                            <p>Wi-Fi setup via mobile phone, tablet or computer</p>
                            <ul class="instruction-steps">
                                <li>1.	Press and hold the Track 2.0 button until the light starts blinking purple</li>
                                <li>2.	Release the button for two seconds</li>
                                <li>3.	Press and hold the button again until the light turns a solid purple</li>
                                <li>4.	With a smart phone or a WiFi-enabled computer, search the list of available WiFi.</li>
                                <li>5.	Select the WiFi named “Mytaptrack”.</li>
                                <li class="hint"><i>NOTE: The WiFi might take up to 30 seconds to show up in your list of WiFi options</i></li>
                                <li>6.  After connecting open a web browser and browse to "http://10.10.0.1"</li>
                                <li>7.  The webpage for the device will allow you to connect to your local WiFi network</li>
                                <li>8.  Click the button when done to complete WiFi setup</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-loading *ngIf="loading" [absolute]="true" text="Saving"></app-loading>
    </div>
    <div class="col-8 details" *ngIf="!isNew && !selected">
        <app-loading *ngIf="loading" [absolute]="true" [text]="loadingText"></app-loading>
    </div>
    <div class="col-8 details" *ngIf="showAppComponent">
        <app-manage-template *ngIf="templatesOnly" [student]="student" [selected]="selected"></app-manage-template>
        <device-app *ngIf="!templatesOnly" [hideName]="false" [student]="student" [selected]="selected" [administrator]="administrator" [abcEnabled]="abcEnabled" [intensityEnabled]="intensityEnabled" [groupsEnabled]="false" (onSelectedChanged)="setSelection($event)" [readOnly]="readonly"></device-app>
    </div>
    <div class="col-8 details" *ngIf="selected && !selected.isNew && !selected.isApp">
        <app-loading *ngIf="loading" [absolute]="true" text="Saving"></app-loading>
        <div class="form-group">
            <label>Name</label>
            <input id="student-setting-device-track-2-0-nn-name" class="form-control" [(ngModel)]="selected.deviceName" [readonly]="!administrator" />
        </div>
        <div class="form-group">
            <label>Switch prompt</label>
            <div class="bold" *ngIf="selected.term && !codewordSetupInProgress">To switch to this student hold the button down and say "Switch to {{selected.term}}"</div>
            <div><a class="link-item" *ngIf="!codewordSetupInProgress" (click)="setupCodeword()">Setup Code Word</a></div>
            <div *ngIf="codewordSetupInProgress && !loading" class="code-word-setup text-center">
                <i class="fa fa-circle-o-notch fa-spin"></i>
            </div>
            <div class="hint" *ngIf="codewordSetupInProgress">
                <span>To set a codeword:</span> 
                <ul>
                    <li>1. Hold down the Track 2.0 button</li>
                    <li>2. Wait for the light to turn green</li>
                    <li>3. Say "Set switch to" then say the code word you want to use for the student</li>
                    <li>4. Release the button and confirm the specified code word is now shown on the student dashboard</li>
                </ul>
            </div>
        </div>
        <div class="form-group">
            <div class="student-device-action" *ngFor="let event of selected.events">
                <label *ngIf="event.presses == 1">1 Click:</label>
                <label *ngIf="event.presses > 1 && event.presses &lt; 10">{{event.presses}} Clicks:</label>
                <label *ngIf="event.presses == 'L'"><i class="fa fa-minus"></i> Long:</label>
                <div>
                    <select class="form-control selectpicker" [(ngModel)]="event.eventId" [disabled]="!administrator">
                        <option value=" "></option>
                        <option *ngFor="let behavior of activeBehaviors" value="{{behavior.id}}">{{behavior.name}}</option>
                    </select>
                </div>
                <div class="col-6" *ngIf="selected.enterpriseId">
                    <select class="form-control" [(ngModel)]="event.delayDelivery">
                        <option value="30">30 minutes</option>
                        <option value="60">1 hour</option>
                        <option value="240">4 hours</option>
                        <option value="EOD">12 AM</option>
                    </select>
                </div>
            </div>
        </div>
        <div>
            <span><b>DSN:</b> {{selected.dsn}}</span>
        </div>
        <div class="text-center">
            <button id="student-setting-device-save" class="btn btn-primary" (click)="trackDevice.save()">Save <i *ngIf="trackDevice.saving" class="fa fa-circle-o-notch fa-spin"></i></button>
            <button id="student-setting-device-cancel" class="btn btn-secondary" (click)="trackDevice.cancel()">Cancel <i *ngIf="trackDevice.saving" class="fa fa-circle-o-notch fa-spin"></i></button>
            <button id="student-setting-device-reset" *ngIf="buttonVersion != 1" class="btn btn-warning" (click)="trackDevice.resyncSecurity()">Reset Security <i *ngIf="trackDevice.saving" class="fa fa-circle-o-notch fa-spin"></i></button>
            <button id="student-setting-device-remove" class="btn btn-danger" (click)="trackDevice.delete()" *ngIf="administrator">Remove <i *ngIf="trackDevice.saving" class="fa fa-circle-o-notch fa-spin"></i></button>
        </div>
        <div class="status">
            {{status}}
        </div>
    </div>
</div>
