<div class="chart-container">
    <canvas #graphingElement baseChart 
        [id]="id"
        [width]="width" [height]="height"
        [data]="chart?.data"
        [options]="chart?.options"
        [type]="chart?.type"
        [legend]="false"
        [plugins]="inlinePlugin"
        [class.chart-container-full]="enablePrint"
        (chartClick)="goToReportDetailsFromLine($event, true)"></canvas>
    <svg #chartMilestones class="milestone" [id]="id+'svg'" 
        [class.chart-container-full]="enablePrint">
    </svg>
</div>
